import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CustomTextField from "../CustomTags/CustomTextField";
import Grid from "@material-ui/core/Grid";

import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import 'moment/locale/de';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';

import erklaerungsTexte from "../LogicData/erklaerungsTexte";
import erklaerungsUeberschriften from "../LogicData/erklaerungsUeberschriften";
import ErsteSchritteSchritte from "../LogicData/ErsteSchritteSchritte";
import styles from "../styles";
import {AppContext} from "../AppContext";
import CustomMultilineTextField from "../CustomTags/CustomMultilineTextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AvatarImagePath from "../CustomTags/beratungsheld-logo-wasserzeichen.png";
import Dropzone from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

class ErsteSchritte extends React.Component {
  static contextType = AppContext;
  editor = React.createRef();

  constructor(props) {
    super(props);

    this.erklaerungen = {
      ...erklaerungsUeberschriften.Unternehmen.ErsteSchritte,
      ...erklaerungsTexte,
    };

    var allEingabeItems = [];

    Object.keys(ErsteSchritteSchritte).forEach(function (outerKey) {
      Object.keys(ErsteSchritteSchritte[outerKey]).forEach(function (
        innerKey
      ) {
        if (ErsteSchritteSchritte[outerKey][innerKey]["inEingabe"] === true) {
          allEingabeItems = [...allEingabeItems, innerKey];
        } else {
          delete ErsteSchritteSchritte[outerKey][innerKey];
        }
      });
    });

    this.schritte = ErsteSchritteSchritte;

    this.allItems = allEingabeItems;

    this.allItemsReversed = {};

    this.allItems.map((element, key) => {
      if(element=="gesuchtWird") {
        this.allItemsReversed[element] = [];
      } else {
        this.allItemsReversed[element] = "";
      }
    });

    this.allItemsReversed.unternehmenGruendungsdatum = moment.utc();

    this.state = {
      step: 1,
      isLoaded: false,
      tutorialEnabled: true,
      ergebnisVorhanden: false,
      erklaerungHeader: this.erklaerungen["erklaerungHeader"],
      erklaerungText: this.erklaerungen["erklaerungText"],
      items: this.allItemsReversed,

      img: "",
      image: AvatarImagePath,
      position: {x: 0.5, y: 0.5},
      scale: 1,
      rotate: 0,
      borderRadius: 0,
      preview: undefined,
      width: 400,
      height: 400,
      disableCanvasRotation: false,
      isTransparent: false,
      backgroundColor: undefined,
      color: [0, 0, 0, 0.5],
      showGrid: false,
    };
  }

  handleNewImage = (e) => {
    if (e.target.files?.[0]) {
      this.setState({image: e.target.files[0]})
    }
  }

  handleSave = () => {
    const img = this.editor.current?.getImageScaledToCanvas().toDataURL()
    const rect = this.editor.current?.getCroppingRect()

    console.log("Preview!");
    console.log(img);
    console.log(rect);

    if (!img || !rect) return

    console.log("Preview can be done!");

    this.setState({
      preview: {
        img,
        rect,
        scale: this.state.scale,
        width: this.state.width,
        height: this.state.height,
        borderRadius: this.state.borderRadius,
      },
    })
  }

  handleScale = (e) => {
    const scale = parseFloat(e.target.value)
    this.setState({scale})
  }

  handleDisableCanvasRotation = (e) => {
    this.setState({disableCanvasRotation: e.target.checked})
  }

  rotateScale = (e) => {
    e.preventDefault()
    this.setState({rotate: parseFloat(e.target.value)})
  }

  rotateLeft = (e) => {
    e.preventDefault()
    this.setState({rotate: (this.state.rotate - 90) % 360})
  }

  rotateRight = (e) => {
    e.preventDefault()
    this.setState({rotate: (this.state.rotate + 90) % 360})
  }

  handleBorderRadius = (e) => {
    this.setState({borderRadius: parseInt(e.target.value)})
  }

  handleXPosition = (e) => {
    this.setState({
      position: {...this.state.position, x: parseFloat(e.target.value)},
    })
  }

  handleYPosition = (e) => {
    this.setState({
      position: {...this.state.position, y: parseFloat(e.target.value)},
    })
  }

  handleWidth = (e) => {
    this.setState({width: parseInt(e.target.value)})
  }

  handleHeight = (e) => {
    this.setState({height: parseInt(e.target.value)})
  }

  logCallback(e) {
    console.log('callback', e)
  }

  handlePositionChange = (position) => {
    this.setState({position})
  }

  setBackgroundColor = (e) => {
    this.setState({backgroundColor: e.target.value})
  }

  //create full hex
  fullHex = (hex) => {
    let r = hex.slice(1, 2);
    let g = hex.slice(2, 3);
    let b = hex.slice(3, 4);

    r = parseInt(r + r, 16);
    g = parseInt(g + g, 16);
    b = parseInt(b + b, 16);

    // return {r, g, b}
    return [r, g, b];
  }

//convert hex to rgb
  hex2rgb = (hex) => {
    if (hex.length === 4) {
      return this.fullHex(hex);
    }

    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return [r, g, b];
  }

  setColor = (e) => {
    let color = this.hex2rgb(e.target.value);
    this.setState({color: color})
  }

  setTransparent = (e) => {
    const isTransparent = e.target.checked
    // set color to white initially
    const backgroundColor = isTransparent ? '#fff' : undefined

    this.setState({backgroundColor, isTransparent})
  }

  handleShowGrid = (e) =>
    this.setState({showGrid: e.target.checked})

  componentDidMount() {
    this.context.setFunktion({
      key: this.erklaerungen.key,
      name: this.erklaerungen.name,
      erklaerungHeader: this.erklaerungen.erklaerungHeader,
      erklaerungText: this.erklaerungen.erklaerungText,
      continuePossible: false,
      backPossible: false,
      totalSteps: Object.keys(this.schritte).length,
      handleSubmit: () => this.handleSubmit(),
      resetToStepOne: () => this.resetToStepOne(),
    });
    this.context.setSchritt({
      key: 1,
      erklaerungHeader: this.erklaerungen["Schritte"][1]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][1].erklaerungText,
      continuePossible: true,
      backPossible: false,
    });
    this.context.setBereich({
      name: "Unternehmen"
    });
    // this.getDataFromApi();
    this.context.setSavePossible(true);
  }

  componentDidUpdate() {
    if (this.state.step !== this.context.schritt.key) {
      this.handleStepChange();
    }
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

  resetToStepOne = async () => {
    this.context.setFunktion({
      key: this.erklaerungen.key,
      name: this.erklaerungen.name,
      erklaerungHeader: this.erklaerungen.erklaerungHeader,
      erklaerungText: this.erklaerungen.erklaerungText,
      continuePossible: false,
      backPossible: false,
      totalSteps: Object.keys(this.schritte).length,
      handleSubmit: () => this.handleSubmit(),
      resetToStepOne: () => this.resetToStepOne(),
    });
    this.context.setSchritt({
      key: 1,
      erklaerungHeader: this.erklaerungen["Schritte"][1]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][1].erklaerungText,
      continuePossible: true,
      backPossible: false,
    });
    this.context.setBereich({
      name: "Unternehmen"
    });
    // this.getDataFromApi();
    this.context.setSavePossible(true);
    await this.delay(1000);
    // window.location.assign("/#/steckbrieftext")
    this.props.history.push("/steckbrieftext");
  }

  getClearData = () => {
    return this.allItemsReversed;
  };

  getDataFromApi = async () => {
    let result = await this.context.getUnternehmenDataFromApi("pitchdeck");

    if (result.pitchdeck) {
      if (
        result.pitchdeck["TextteilIntro"] &&
        0 !== result.pitchdeck["TextteilIntro"].toString().length
      ) {
        this.setState({
          isLoaded: true,
          ergebnisVorhanden: true,
          items: {
            ...this.getClearData(),
            ...result.pitchdeck,
          },
        });
      } else {
        this.setState({
          isLoaded: true,
          ergebnisVorhanden: false,
          items: {
            ...this.getClearData(),
            ...result.pitchdeck,
          },
        });
      }
    } else {
      this.setState({
        isLoaded: false,
        ergebnisVorhanden: false,
        items: this.getClearData(),
      });
    }
  }

  continuePossible = (step) => {
    switch (step) {
      case 1:
      case 2:
      case 3:
      case 4:
        return true;
      case 5:
        return true;
      default:
        return false;
    }
  };

  handleSnackbarClose = (event, reason) => {
    this.setState({
      saveSuccessful: false,
    });
  };

  handleChange = (name, typ) => ({target: {value, checked}}) => {
    const {items} = this.state;

    this.setState({
      items: {
        ...items,
        [name]: value,
      },
    });
  };

  handleDateChange = (name, newDate) => {
    const {items} = this.state;

    this.setState({
      items: {
        ...items,
        [name]: newDate,
      },
    });
  };

  handleFocus = (name) => {
    this.setState({
      erklaerungHeader: this.erklaerungen[name].erklaerungHeader,
      erklaerungText: this.erklaerungen[name].erklaerungText,
    });
    this.context.setFeld({
      key: name,
    });
  };

  handleFocusEvent = (name, event) => {
    this.setState({
      erklaerungHeader: this.erklaerungen[name].erklaerungHeader,
      erklaerungText: this.erklaerungen[name].erklaerungText,
    });
    event.target.select();
    this.context.setFeld({
      key: name,
    });
  };

  handleStepChange = () => {
    const newStep = this.context.schritt.key;
    if (newStep === undefined || this.erklaerungen["Schritte"][newStep] === undefined) {
      console.log("UNDEFINED!!!");
      return;
    } else {
      console.log("DEFINED!!!");
      this.setState({
        step: newStep,
        erklaerungHeader: this.erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
      });

      if (newStep == 3) {
        const img = this.editor.current?.getImageScaledToCanvas().toDataURL();
        this.setState({
          img: img,
        })
      }
      if (newStep > 1) {
        this.context.setSchritt({
          key: newStep,
          erklaerungHeader: this.erklaerungen["Schritte"][newStep]
            .erklaerungHeader,
          erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
          continuePossible: this.continuePossible(newStep),
          backPossible: true,
        });
      } else {
        this.context.setSchritt({
          key: newStep,
          erklaerungHeader: this.erklaerungen["Schritte"][newStep]
            .erklaerungHeader,
          erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
          continuePossible: this.continuePossible(newStep),
          backPossible: false,
        });
      }
    }
  };

  handleBack = () => {
    const {step} = this.state;
    if (step > 1) {
      this.setState({
        step: step - 1,
        erklaerungHeader: this.erklaerungen["Schritte"][step - 1]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][step - 1].erklaerungText,
      });
    }
  };

  handleSubmit = async () => {
    const {items, img} = this.state;
    const rumpfjahr = parseInt(items.unternehmenGruendungsdatum.format('YYYY'));
    const rumpfmonat = parseInt(items.unternehmenGruendungsdatum.format('MM'));
    let response = await this.context.postOtherUnternehmenData(null,
      items.unternehmenName, items, rumpfjahr, rumpfmonat, img);
    let response2 = await this.context.saveStandardunternehmen(response._id, response.unternehmenName);
    this.context.refreshUnternehmenListe();
    this.context.changeUnternehmen(response._id, response.unternehmenName);
    // window.location.replace("/");
    // let result = await this.context.postUnternehmenDataToApi("pitchdeck", items);
    //
    // this.setState({
    //   isLoaded: true,
    //   items: result.pitchdeck,
    //   saveSuccessful: true,
    // });
  }



  createField = (name, properties) => {
    const {classes} = this.props;
    const {items, vergleichsjahr} = this.state;
    if (properties.typ === "readOnly") {
      return (
        <CustomTextField
          id={"edit-textteil-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name, "input")}
          margin="normal"
          isCurrency={true}
          isReadOnly={true}
          unit={"€"}
          onFocus={() => this.handleFocus(name)}
        />
      );
    } else if (properties.typ === "input") {
      return (
        <CustomTextField
          id={"edit-textteil-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name, "input")}
          margin="normal"
          variant="outlined"
          isCurrency={true}
          isReadOnly={false}
          unit={"€"}
          onFocus={(event) => this.handleFocusEvent(name, event)}
        />
      );
    } else if (properties.typ === "singleline") {
      return (
        <CustomTextField
          id={"edit-textteil-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name, "input")}
          margin="normal"
          variant="outlined"
          isText={true}
          isCurrency={false}
          isReadOnly={false}
          unit={""}
          onFocus={(event) => this.handleFocusEvent(name, event)}
        />
      );
    } else if (properties.typ === "multiline") {
      return (
        <CustomMultilineTextField
          id={"edit-textteil-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.multilineTextField}
          value={items[name]}
          onChange={this.handleChange(name, "multiline")}
          margin="normal"
          variant="outlined"
          isCurrency={false}
          isReadOnly={false}
          onFocus={(event) => this.handleFocusEvent(name, event)}
        />
      );
    } else if (properties.typ === "multiline-readonly") {
      return (
        <CustomMultilineTextField
          id={"edit-textteil-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.multilineTextField}
          value={items[name]}
          onChange={this.handleChange(name, "multiline")}
          margin="normal"
          variant="outlined"
          isCurrency={false}
          isReadOnly={true}
          onFocus={(event) => this.handleFocusEvent(name, event)}
        />
      );
    } else if (properties.typ === "image") {
      return (
        <div>
          Falls gewünscht/vorhanden ein Bild in den grauen Kasten ziehen.
          <Dropzone
            onDrop={([image]) => this.setState({image})}
            multiple={false}
          >
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} className="preview">
                <AvatarEditor
                  ref={this.editor}
                  scale={this.state.scale}
                  width={this.state.width}
                  height={this.state.height}
                  position={this.state.position}
                  showGrid={this.state.showGrid}
                  onPositionChange={this.handlePositionChange}
                  rotate={this.state.rotate}
                  borderRadius={
                    this.state.width / (100 / this.state.borderRadius)
                  }
                  backgroundColor={this.state.backgroundColor}
                  color={this.state.color}
                  onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
                  onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
                  onImageReady={this.logCallback.bind(this, 'onImageReady')}
                  image={this.state.image}
                  disableCanvasRotation={this.state.disableCanvasRotation}
                  disableBoundaryChecks={true}
                />
                <input
                  name="newImage"
                  type="file"
                  onChange={this.handleNewImage}
                  {...getInputProps()}
                />
              </div>
            )}
          </Dropzone>
          Zoom:{' '}
          <input
            name="scale"
            type="range"
            onChange={this.handleScale}
            min="0.1"
            max="2"
            step="0.01"
            defaultValue="1"
          />
          <br/>
          Drehen:
          <button onClick={this.rotateLeft}>Links herum</button>
          <button onClick={this.rotateRight}>Rechts herum</button>
        </div>
      );
    } else if (properties.typ === "select") {
      let selectionItems = [
        // <MenuItem value="">
        //   Bitte wählen
        // </MenuItem>
      ];
      properties.selection.forEach(selectionItem => {
        selectionItems.push(
          <MenuItem value={selectionItem}>
            {selectionItem}
          </MenuItem>
        )
      })
      return (
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            id={"select-" + name + "-label"}
            variant="outlined"
            required
          >
            {this.erklaerungen[name]["kurzName"]}
          </InputLabel>
          <Select
            labelId={"select-" + name + "-label"}
            id={"select-" + name + "-field"}
            value={items[name]}
            onChange={this.handleChange(name, "select")}
          >
            {selectionItems}
          </Select>
        </FormControl>
      );
    } else if (properties.typ === "multiselect") {
      let selectionItems = [
        // <MenuItem value="">
        //   Bitte wählen
        // </MenuItem>
      ];
      properties.selection.forEach(selectionItem => {
        selectionItems.push(
          <MenuItem value={selectionItem} key={selectionItem}>
            {selectionItem}
          </MenuItem>
        )
      })
      return (
        <FormControl variant="outlined" className={classes.formControlWide}>
          <InputLabel
            id={"multiselect-" + name + "-label"}
            variant="outlined"
            required
          >
            {this.erklaerungen[name]["kurzName"]}
          </InputLabel>
          <Select
            labelId={"multiselect-" + name + "-label"}
            id={"multiselect-" + name + "-field"}
            value={items[name]}
            onChange={this.handleChange(name, "select")}
            multiple
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {selectionItems}
          </Select>
        </FormControl>
      );
    } else if (properties.typ === "date") {
      return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'de'}>
          {/*<DateField label="Date" defaultValue={moment('2022-04-17')} />*/}
          <DatePicker
            className={classes.formControl}
            variant="outlined"
            views={['month', 'year']}
            value={items[name]}
            onChange={(newDate) => this.handleDateChange(name, newDate)}
            label={this.erklaerungen[name]["kurzName"]}
            sx={{
              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                borderRadius: "30px !important",
              },
            }}
          />
          {/*<CustomMultilineTextField*/}
          {/*  id={"edit-textteil-" + name}*/}
          {/*  label={this.erklaerungen[name]["kurzName"]}*/}
          {/*  className={classes.multilineTextField}*/}
          {/*  value={items[name]}*/}
          {/*  onChange={this.handleChange(name, "multiline")}*/}
          {/*  margin="normal"*/}
          {/*  variant="outlined"*/}
          {/*  isCurrency={false}*/}
          {/*  isReadOnly={true}*/}
          {/*  onFocus={(event) => this.handleFocusEvent(name, event)}*/}
          {/*/>*/}
        </LocalizationProvider>
      );
    }
  };

  createStepCard = (step) => {
    const {classes} = this.props;
    return (
      <Card className={classes.card}>
        <CardHeader
          title={this.erklaerungen["Schritte"][step]["erklaerungHeader"]}
        />
        <CardContent>
          {Object.keys(this.schritte[step]).map((feld) => {
            return this.createField(feld, this.schritte[step][feld]);
          })}
        </CardContent>
      </Card>
    );
  };

  render() {
    const {classes} = this.props;
    const {step} = this.state;

    return (
      <Fragment>
        <div className={classes.appBarSpacer}/>
        <Typography variant="h4" gutterBottom component="h2">
          {this.erklaerungen.erklaerungHeader}
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="stretch"
          spacing={2}
          className={classes.fullheightGrid}>
          <Grid item md={12} lg={12}>
            <form className={classes.container} noValidate autoComplete="off">
              {this.createStepCard(step)}
            </form>
          </Grid>
        </Grid>

        {/*<Card className={classes.progressCard}>*/}
        {/*  <CardHeader title="Fortschritt"/>*/}
        {/*  <CardContent>*/}
        {/*    <LinearProgress*/}
        {/*      variant="determinate"*/}
        {/*      value={((step - 1) / (Object.keys(this.erklaerungen["Schritte"]).length - 1)) * 100}*/}
        {/*    />*/}
        {/*    <Typography variant="body1">*/}
        {/*      {this.erklaerungen.erklaerungHeader} &mdash; Schritt {step} von {(Object.keys(this.erklaerungen["Schritte"]).length)}: {this.context.schritt.erklaerungHeader}*/}
        {/*    </Typography>*/}
        {/*  </CardContent>*/}
        {/*</Card>*/}
      </Fragment>
    );
  }
}

export default withStyles(styles)(ErsteSchritte);
