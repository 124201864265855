import Typography from "@material-ui/core/Typography/Typography";
import React, {Fragment} from "react";
import {Paper, withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CustomTextField from "../CustomTags/CustomTextField";
import Grid from "@mui/material/Grid";

import liquiditaetsSchritte from "../LogicData/Liquiditaetsschritte";
import rentabilitaetsSchritte from "../LogicData/RentabilitaetsSchritte";
import kapitalUndFinanzierungsSchritte from "../LogicData/KapitalUndFinanzierungsSchritte";
import privaterFinanzBedarfSchritte from "../LogicData/PrivaterFinanzbedarfSchritte2";
import typischerMonatSchritte from "../LogicData/TypischerMonatSchritte";
import erklaerungsTexte from "../LogicData/erklaerungsTexte";
import erklaerungsUeberschriften from "../LogicData/erklaerungsUeberschriften";
import SteckbriefShowSchritte from "../LogicData/SteckbriefShowSchritte";
import styles from "../styles";
import {AppContext} from "../AppContext";
import CustomMultilineTextField from "../CustomTags/CustomMultilineTextField";
import CustomCell from "../CustomTags/CustomCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import WeiterleitungsModal from "../CustomTags/Weiterleitungsmodal";
import CustomDarkCell from "../CustomTags/CustomDarkCell";
import TableRow from "@material-ui/core/TableRow";
import TopAreaDual from "../MenuItems/TopAreaDual";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";

const Row = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
    "&.summe": {
      backgroundColor: "#222",
      paddingBottom: "80px",
      //fontWeight: "800",
    },
    "&.zwischenSumme": {
      backgroundColor: "#9e9e9e",
      paddingBottom: "80px",
      //fontWeight: "800",
    },
  },
}))(TableRow);

class SteckbriefShowPublic extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.erklaerungen = {
      ...erklaerungsUeberschriften.Steckbrief.Steckbrief,
      ...erklaerungsTexte,
    };

    var allEingabeItems = [];

    Object.keys(SteckbriefShowSchritte).forEach(function (outerKey) {
      Object.keys(SteckbriefShowSchritte[outerKey]).forEach(function (
        innerKey
      ) {
        if (SteckbriefShowSchritte[outerKey][innerKey]["inEingabe"] === true) {
          allEingabeItems = [...allEingabeItems, innerKey];
        } else {
          delete SteckbriefShowSchritte[outerKey][innerKey];
        }
      });
    });

    this.schritte = SteckbriefShowSchritte;

    this.allItems = allEingabeItems;

    this.allItemsReversed = {};

    this.allItems.map((element, key) => {
      this.allItemsReversed[element] = "";
    });

    this.state = {
      kapitalUndFinanzierungsdaten: null,
      privaterFinanzBedarfdaten: null,
      //umsatzsteuersaldo: null,
      ueber_unterDeckung: null,
      ueber_unterDeckung_Privatentnahme: null,
      liquideMittelAmMonatsende: [],
      liquiditaetVormonat: [],
      liquideMittelAmMonatsendeInklKontokorrentRahmen: [],
      typischerMonatData: null,
      spezifischerMonatData: null,
      bwaData: null,
      liquiditaetrows: [],
      bauinverstition: null,
      kapitalUndFinanzierungsrows: [],
      rentabilitaetrows: [],
      privaterFinanzBedarfrows: [],
      rumpfjahr: null,
      rumpfmonat: null,
      textteil: [],
      isLoaded: false,
      weiterleitungsModalOpen: false,
      modalScript: "",
      showTable: false,
      step: 1,
      tutorialEnabled: true,
      finanzteilVorhanden: false,
      pitchDeckFinancesPublic: false,
      erklaerungHeader: this.erklaerungen["erklaerungHeader"],
      erklaerungText: this.erklaerungen["erklaerungText"],
      items: this.allItemsReversed,
      unternehmenName: '',
    };

    let LiquiditaetsSchrittName = [];
    let KapitalUndFinanzierungsSchrittName = [];
    let RentabilitaetsSchrittName = [];
    let PrivaterFinanzbedarfSchrittName = [];

    //LiquiditaetsSchritte

    Object.keys(liquiditaetsSchritte).forEach(function (outerKey) {
      Object.keys(liquiditaetsSchritte[outerKey]).forEach(function (innerKey) {
        if (liquiditaetsSchritte[outerKey][innerKey]["inAusgabe"] === true) {
          if ("class" in liquiditaetsSchritte[outerKey][innerKey]) {
            LiquiditaetsSchrittName = {
              ...LiquiditaetsSchrittName,

              [innerKey]: {
                class: liquiditaetsSchritte[outerKey][innerKey]["class"],
                step: outerKey,
              },
            };
          } else {
            LiquiditaetsSchrittName = {
              ...LiquiditaetsSchrittName,
              [innerKey]: {
                class: "",
                step: outerKey,
              },
            };
          }
        } else {
          delete liquiditaetsSchritte[outerKey][innerKey];
        }
      });
    });
    this.state.liquiditaetrows = LiquiditaetsSchrittName;

    //Rentabiliaetsschritte
    Object.keys(rentabilitaetsSchritte).forEach(function (firstKey) {
      Object.keys(rentabilitaetsSchritte[firstKey]).forEach(function (
        secondKey
      ) {
        if (rentabilitaetsSchritte[firstKey][secondKey]["inAusgabe"] === true) {
          if ("class" in rentabilitaetsSchritte[firstKey][secondKey]) {
            RentabilitaetsSchrittName = {
              ...RentabilitaetsSchrittName,

              [secondKey]: {
                class: rentabilitaetsSchritte[firstKey][secondKey]["class"],
                step: firstKey,
              },
            };
          } else {
            RentabilitaetsSchrittName = {
              ...RentabilitaetsSchrittName,
              [secondKey]: {
                class: "",
                step: firstKey,
              },
            };
          }
        } else {
          delete rentabilitaetsSchritte[firstKey][secondKey];
        }
      });
    });
    this.state.rentabilitaetrows = RentabilitaetsSchrittName;

    //Kapital- und FinanzierungsSchritte
    Object.keys(kapitalUndFinanzierungsSchritte).forEach(function (outkey) {
      Object.keys(kapitalUndFinanzierungsSchritte[outkey]).forEach(function (
        inKey
      ) {
        if (
          kapitalUndFinanzierungsSchritte[outkey][inKey]["inAusgabe"] === true
        ) {
          if ("class" in kapitalUndFinanzierungsSchritte[outkey][inKey]) {
            KapitalUndFinanzierungsSchrittName = {
              ...KapitalUndFinanzierungsSchrittName,
              //              fourthKey,
              [inKey]: {
                class: kapitalUndFinanzierungsSchritte[outkey][inKey]["class"],
                step: outkey,
              },
            };
          } else {
            KapitalUndFinanzierungsSchrittName = {
              ...KapitalUndFinanzierungsSchrittName,
              //              fourthKey,
              [inKey]: {
                class: "",
                step: outkey,
              },
            };
          }
        } else {
          delete kapitalUndFinanzierungsSchritte[outkey][inKey];
        }
      });
    });

    this.state.kapitalUndFinanzierungsrows = KapitalUndFinanzierungsSchrittName;

    //Privater FinanzbedarfSchritte
    Object.keys(privaterFinanzBedarfSchritte).forEach(function (thirdKey) {
      Object.keys(privaterFinanzBedarfSchritte[thirdKey]).forEach(function (
        fourthKey
      ) {
        if (
          privaterFinanzBedarfSchritte[thirdKey][fourthKey]["inAusgabe"] ===
          true
        ) {
          if ("class" in privaterFinanzBedarfSchritte[thirdKey][fourthKey]) {
            PrivaterFinanzbedarfSchrittName = {
              ...PrivaterFinanzbedarfSchrittName,
              //              fourthKey,
              [fourthKey]: {
                class:
                  privaterFinanzBedarfSchritte[thirdKey][fourthKey]["class"],
                step: thirdKey,
              },
            };
          } else {
            PrivaterFinanzbedarfSchrittName = {
              ...PrivaterFinanzbedarfSchrittName,
              //              fourthKey,
              [fourthKey]: {
                class: "",
                step: thirdKey,
              },
            };
          }
        } else {
          delete privaterFinanzBedarfSchritte[thirdKey][fourthKey];
        }
      });
    });

    this.state.privaterFinanzBedarfrows = PrivaterFinanzbedarfSchrittName;

    this.state.typischerMonatReversed = this.getClearData("typischerMonatSchritte");
  }

  tableHeadMonate = () => {
    return (
      <Fragment>
        <CustomCell align="right" value={"Januar"}/>
        <CustomCell align="right" value={"Februar"}/>
        <CustomCell align="right" value={"März"}/>
        <CustomCell align="right" value={"April"}/>
        <CustomCell align="right" value={"Mai"}/>
        <CustomCell align="right" value={"Juni"}/>
        <CustomCell align="right" value={"Juli"}/>
        <CustomCell align="right" value={"August"}/>
        <CustomCell align="right" value={"September"}/>
        <CustomCell align="right" value={"Oktober"}/>
        <CustomCell align="right" value={"November"}/>
        <CustomCell align="right" value={"Dezember"}/>
        <CustomCell align="right" value={"Summe"}/>
      </Fragment>
    );
  };

  componentDidMount() {
    this.getDataFromApi();
  }

  getClearData = (name) => {
    switch (name) {
      case "liquiditaetsSchritte":
        return (this.reloadSchrittData(this.state.liquiditaetrows));
      case "rentabilitaetsSchritte":
        return (this.reloadSchrittData(this.state.rentabilitaetrows));
      case "kapitalUndFinanzierungsSchritte":
        return (this.reloadSchrittData(this.state.kapitalUndFinanzierungsrows));
      case "privaterFinanzBedarfSchritte":
        return (this.reloadSchrittData(this.state.privaterFinanzBedarfrows));
      case "typischerMonatSchritte":
        return (this.reloadSchrittData(typischerMonatSchritte));
      default:
        return ({});
    }
  };

  reloadSchrittData = (SchrittFile) => {
    let allItems = [];

    Object.keys(SchrittFile).forEach(function (outerKey) {
      Object.keys(SchrittFile[outerKey]).forEach(function (innerKey) {
        if (SchrittFile[outerKey][innerKey]["inAusgabe"] === true) {
          allItems = [...allItems, innerKey];
        } else {
          delete SchrittFile[outerKey][innerKey];
        }
      });
    });

    let allItemsReversed = {};

    allItems.map((element, key) => {
      allItemsReversed[element] = 0;
    });

    return (allItemsReversed);
  }

  // getDataFromApi = async () => {
  //   let result = await this.context.getUnternehmenDataFromApi("pitchdeck");
  //
  //   if (result.pitchdeck) {
  //     if (
  //       result.pitchdeck["TextteilIntro"] &&
  //       0 !== result.pitchdeck["TextteilIntro"].toString().length
  //     ) {
  //       this.setState({
  //         isLoaded: true,
  //         ergebnisVorhanden: true,
  //         items: {
  //           ...this.getClearData(),
  //           ...result.pitchdeck,
  //         },
  //       });
  //     } else {
  //       this.setState({
  //         isLoaded: true,
  //         ergebnisVorhanden: false,
  //         items: {
  //           ...this.getClearData(),
  //           ...result.pitchdeck,
  //         },
  //       });
  //     }
  //   } else {
  //     this.setState({
  //       isLoaded: false,
  //       ergebnisVorhanden: false,
  //       items: this.getClearData(),
  //     });
  //   }
  // }

  getDataFromApi = async () => {
    // let result = await this.context.getUnternehmenDataFromApi("all");

    let response = await fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/Unternehmen?filter[_id]=${this.props.match.params.id}&limit=1`, {headers: {'Cockpit-Token': 'account-08a04d1fe5bbb21334f6a76c06b38e'}})
    console.log(response);
    let json = await response.json();
    console.log("getUnternehmenDataFromCockpitApi: json.entries");
    console.log(json.entries);
    this.setState({
      unternehmenData: json.entries[0]
    });
    console.log("getUnternehmenDataFromCockpitApi: returning");
    let result = json.entries[0];

    let typischerMonatData;
    let spezifischerMonatData;
    let privaterFinanzBedarfdaten;
    let kapitalUndFinanzierungsdaten;
    let Steckbrief;
    let bwaData;
    let rumpfjahr = result.rumpfjahr;
    let rumpfmonat = result.rumpfmonat;

    if (
      result.pitchdeck &&
      "pitchDeckPublic" in result.pitchdeck &&
      result.pitchdeck["pitchDeckPublic"]
    ) {
      Steckbrief = result.pitchdeck;
    } else {
      this.setState({
        weiterleitungsModalOpen: true,
        modalScript: "SteckbriefUnpublished",
      });
      return;
    }

    if (
      result.privatFinanzen &&
      "PrivatBenoetigtePrivatentnahme" in result.privatFinanzen &&
      0 !==
      result.privatFinanzen["PrivatBenoetigtePrivatentnahme"].toString()
        .length
    ) {
      privaterFinanzBedarfdaten = result.privatFinanzen;
    } else {
      this.setState({
        showTable: false,
        // weiterleitungsModalOpen: true,
        // modalScript: "PrivaterFinanzbedarf",
      });
      // return;
    }

    let bauinverstition = "";

    if (
      result.kapitalUndFinanzierung &&
      "FinanzierungFinanzierungslueckeReserve" in
      result.kapitalUndFinanzierung &&
      0 !==
      result.kapitalUndFinanzierung[
        "FinanzierungFinanzierungslueckeReserve"
        ].toString().length
    ) {
      kapitalUndFinanzierungsdaten = result.kapitalUndFinanzierung;
      bauinverstition =
        result.kapitalUndFinanzierung.FinanzierungBauinvestitionen;
    } else {
      this.setState({
        showTable: false,
        // weiterleitungsModalOpen: true,
        // modalScript: "KapitalUndFinanzierung",
      });
      // return;
    }

    if (
      result.typischerMonat &&
      "LiquiditaetEinzahlungen" in result.typischerMonat &&
      0 !== result.typischerMonat["LiquiditaetEinzahlungen"].toString().length
    ) {
      typischerMonatData = result.typischerMonat;
    } else {
      this.setState({
        showTable: false,
        // weiterleitungsModalOpen: true,
        // modalScript: "TypischerMonat",
      });
      // return;
    }

    if (isNaN(rumpfjahr) || isNaN(rumpfmonat)) {
      this.setState({
        showTable: false,
        // weiterleitungsModalOpen: true,
        // modalScript: "SepezifischerMonat",
      });
      // return;
    }

    if (
      result.bwa &&
      rumpfjahr in
      result.bwa &&
      rumpfmonat in
      result.bwa[rumpfjahr] &&
      0 !==
      result.bwa[rumpfjahr][rumpfmonat]["Soll"]["Betriebsergebnis"].toString().length
    ) {
      bwaData = result.bwa;
    } else {
      this.setState({
        showTable: false,
        // weiterleitungsModalOpen: true,
        // modalScript: "BwaDaten",
      });
      // return;
    }

    if (result.spezifischerMonat) {
      spezifischerMonatData = result.spezifischerMonat;
    }

    this.setState({
      privaterFinanzBedarfdaten: privaterFinanzBedarfdaten,
      typischerMonatData: typischerMonatData,
      bauinverstition: bauinverstition,
      kapitalUndFinanzierungsdaten: kapitalUndFinanzierungsdaten,
      spezifischerMonatData: spezifischerMonatData,
      bwaData: bwaData,
      items: Steckbrief,
      rumpfjahr: rumpfjahr,
      rumpfmonat: rumpfmonat,
      isLoaded: true,
      showTable: Steckbrief.pitchDeckFinancesPublic,
      unternehmenName: result.unternehmenName,
    });
  }

  continuePossible = (step) => {
    switch (step) {
      case 1:
        return false;
      default:
        return false;
    }
  };

  handleStepChange = () => {
    const newStep = this.context.schritt.key;
    this.setState({
      step: newStep,
      erklaerungHeader: this.erklaerungen["Schritte"][newStep]
        .erklaerungHeader,
      erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
    });

    if (newStep > 1) {
      this.context.setSchritt({
        key: newStep,
        erklaerungHeader: this.erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
        continuePossible: this.continuePossible(newStep),
        backPossible: true,
      });
    } else {
      this.context.setSchritt({
        key: newStep,
        erklaerungHeader: this.erklaerungen["Schritte"][newStep]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][newStep].erklaerungText,
        continuePossible: this.continuePossible(newStep),
        backPossible: false,
      });
    }
  };

  handleBack = () => {
    const {step} = this.state;
    if (step > 1) {
      this.setState({
        step: step - 1,
        erklaerungHeader: this.erklaerungen["Schritte"][step - 1]
          .erklaerungHeader,
        erklaerungText: this.erklaerungen["Schritte"][step - 1].erklaerungText,
      });
    }
  };

  handleSubmit = async () => {
    const {items} = this.state;

    this.setState({
      isLoaded: true,
    });
  }

  createField = (name, properties) => {
    const {classes} = this.props;
    const {items, vergleichsjahr} = this.state;
    if (properties.typ === "readOnly") {
      return (
        <CustomTextField
          id={"edit-textteil-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name, "input")}
          margin="normal"
          isCurrency={true}
          isReadOnly={true}
          unit={"€"}
          onFocus={() => this.handleFocus(name)}
        />
      );
    } else if (properties.typ === "input") {
      return (
        <CustomTextField
          id={"edit-textteil-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name, "input")}
          margin="normal"
          variant="outlined"
          isCurrency={true}
          isReadOnly={false}
          unit={"€"}
          onFocus={(event) => this.handleFocusEvent(name, event)}
        />
      );
    } else if (properties.typ === "multiline") {
      return (
        <CustomMultilineTextField
          id={"edit-textteil-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.multilineTextField}
          value={items[name]}
          margin="normal"
          variant="outlined"
          isReadOnly={true}
        />
      );
    } else if (properties.typ === "multiline-readonly") {
      return (
        <CustomMultilineTextField
          id={"edit-textteil-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.multilineTextField}
          showLabel={false}
          value={items[name]}
          margin="normal"
          variant="outlined"
          isReadOnly={true}
        />
      );
    }
  };

  createStepCard = (step) => {
    const {classes} = this.props;
    return (
      <Fragment>
        {Object.keys(this.schritte[step]).map((feld) => {
          return (
            <Card className={classes.card}>
              <CardHeader
                title={this.erklaerungen[feld]["kurzName"]}
              />
              <CardContent>
                {this.createField(feld, this.schritte[step][feld])}
              </CardContent>
            </Card>
          )
        })}
      </Fragment>
    );
  };

  loopThroughBwaData = (row, jahr, type) => {
    let {bwaData} = this.state;
    let ausgabe = [];
    let summe = 0;

    if (type === "summe") {
      for (let j = 1; j <= 12; j++) {

        ausgabe.push(
          <CustomDarkCell
            align="right"
            isCurrency={true}
            showCurrency={false}
            unit={"€"}
            value={bwaData[jahr][j]["Soll"][row] || 0}
          />
        )
        summe += bwaData[jahr][j]["Soll"][row] || 0;
      }

      ausgabe.push(
        <CustomDarkCell
          align="right"
          isCurrency={true}
          showCurrency={false}
          unit={"€"}
          value={summe}
        />
      );
      return ausgabe;

    } else {
      for (let j = 1; j <= 12; j++) {

        ausgabe.push(
          <CustomCell
            align="right"
            isCurrency={true}
            showCurrency={false}
            unit={"€"}
            value={bwaData[jahr][j]["Soll"][row] || 0}
          />
        )
        summe += bwaData[jahr][j]["Soll"][row] || 0;
      }

      ausgabe.push(
        <CustomCell
          align="right"
          isCurrency={true}
          showCurrency={false}
          unit={"€"}
          value={summe}
        />
      );
    }

    return ausgabe;
  };

  handletabellenErstellen = (row, jahr, type) => {
    return <Fragment>{this.loopThroughBwaData(row, jahr, type)}</Fragment>;
  };

  continue = () => {
    // this.props.history.push(`${this.state.unternehmenData.unternehmenData.url}`);
    window.location.assign(`${this.state.unternehmenData.unternehmenData.url}`);
  };

  back = () => {
    this.props.history.push('/public/businessforum');
    // this.props.history.goBack();
  };

  render() {
    const {classes} = this.props;
    let {
      step,
      kapitalUndFinanzierungsdaten,
      kapitalUndFinanzierungsrows,
      privaterFinanzBedarfdaten,
      privaterFinanzBedarfrows,
      liquiditaetrows,
      rentabilitaetrows,
      bwaData,
      isLoaded,
      modalScript,
      weiterleitungsModalOpen,
      showTable,
      unternehmenName,
    } = this.state;

    // console.log("render");
    // console.log(this.state);

    let erklaerungstexte = erklaerungsTexte;
    let handletabellenErstellen = this.handletabellenErstellen;
    let tableHeadMonate = this.tableHeadMonate;

    const renderItems = [];
    if (showTable) {
      Object.keys(bwaData).forEach(function (jahr) {
        renderItems.push(
          <TableContainer component={Card} className={classes.fullWidthCard} key={"renta-table-" + jahr}
                          id={`rentabilitaet-${jahr}`}>
            <Table aria-label="customized table" size="small">
              <TableHead>
                <Row>
                  <CustomCell value={"Rentabilität im Jahr " + jahr}/>
                  {tableHeadMonate()}
                </Row>
              </TableHead>
              <TableBody>
                {Object.keys(rentabilitaetrows).map(function (row) {
                  return (
                    <Row
                      key={row}
                      className={
                        rentabilitaetrows[row].class +
                        " step-" +
                        rentabilitaetrows[row].step
                      }
                    >
                      {rentabilitaetrows[row].class === "summe"
                        ? <CustomDarkCell
                          value={erklaerungstexte[row]["kurzName"]}
                          className={rentabilitaetrows[row].class}
                        />
                        : <CustomCell
                          value={erklaerungstexte[row]["kurzName"]}
                          className={rentabilitaetrows[row].class}
                        />
                      }
                      {isLoaded ? (
                        handletabellenErstellen(row, jahr, rentabilitaetrows[row].class)
                      ) : (
                        <CustomCell/>
                      )}
                    </Row>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      });

      Object.keys(bwaData).forEach(function (jahr) {
        renderItems.push(
          <TableContainer component={Card} className={classes.fullWidthCard} key={"liqui-table-" + jahr}
                          id={`liquiditaet-${jahr}`}>
            <Table aria-label="customized table" size="small">
              <TableHead>
                <Row>
                  <CustomCell value={"Liquidität im Jahr " + jahr}/>
                  {tableHeadMonate()}
                </Row>
              </TableHead>
              <TableBody>
                {Object.keys(liquiditaetrows).map(function (row) {
                  return (
                    <Row
                      key={row}
                      className={
                        liquiditaetrows[row].class +
                        " step-" +
                        liquiditaetrows[row].step
                      }
                    >
                      {liquiditaetrows[row].class === "summe"
                        ? <CustomDarkCell
                          value={erklaerungstexte[row]["kurzName"]}
                          className={liquiditaetrows[row].class}
                        />
                        : <CustomCell
                          value={erklaerungstexte[row]["kurzName"]}
                          className={liquiditaetrows[row].class}
                        />
                      }
                      {isLoaded ? (
                        handletabellenErstellen(row, jahr, liquiditaetrows[row].class)
                      ) : (
                        <CustomCell/>
                      )}
                    </Row>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      });
    }

    return (
      <Fragment>
        <TopAreaDual/>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: !this.state.chatColumnOpen,
          })}
        >
          <div className={classes.appBarSpacer}/>
          <WeiterleitungsModal
            userId={this.props.userId}
            unternehmenId={this.props.unternehmenId}
            weiterleitungsModalOpen={weiterleitungsModalOpen}
            modalScript={modalScript}
          />
          <div className={classes.appBarSpacer}/>
          <Typography variant="h4" gutterBottom component="h2">
            {"Steckbrief: " + unternehmenName}
          </Typography>
          <Grid container spacing={2} sx={{mb: 10}}>
            <Grid item md={12} lg={12}>
              <form className={classes.container} noValidate autoComplete="off">
                {this.createStepCard(step)}
              </form>

              {showTable ? (
                <div className={classes.contentItem}>
                  <TableContainer component={Card} className={classes.card}>
                    <Table aria-label="customized table" size="small" id="privaterfinanzbedarf-table">
                      <TableHead>
                        <Row>
                          <CustomCell value={"Privater Finanzbedarf"}/>
                          <CustomCell align="right" value={"Wert"}/>
                        </Row>
                      </TableHead>
                      <TableBody>
                        {Object.keys(privaterFinanzBedarfrows).map(function (
                          row,
                          index
                        ) {
                          return (
                            <Row
                              key={row}
                              className={
                                privaterFinanzBedarfrows[row].class +
                                " step-" +
                                privaterFinanzBedarfrows[row].step
                              }
                            >
                              {privaterFinanzBedarfrows[row].class === "summe"
                                ? <Fragment>
                                  <CustomDarkCell
                                    value={erklaerungstexte[row]["kurzName"]}
                                    className={privaterFinanzBedarfrows[row].class}
                                  />
                                  {isLoaded ?
                                    <CustomDarkCell
                                      align="right"
                                      isCurrency={true}
                                      showCurrency={false}
                                      unit={"€"}
                                      value={privaterFinanzBedarfdaten[row]}
                                      className={privaterFinanzBedarfrows[row].class}
                                    />
                                    : <CustomDarkCell/>
                                  }
                                </Fragment>
                                : <Fragment>
                                  <CustomCell
                                    value={erklaerungstexte[row]["kurzName"]}
                                    className={privaterFinanzBedarfrows[row].class}
                                  />
                                  {isLoaded ?
                                    <CustomCell
                                      align="right"
                                      isCurrency={true}
                                      showCurrency={false}
                                      unit={"€"}
                                      value={privaterFinanzBedarfdaten[row]}
                                      className={privaterFinanzBedarfrows[row].class}
                                    />
                                    : <CustomCell/>
                                  }
                                </Fragment>
                              }
                            </Row>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer component={Card} className={classes.card}>
                    <Table aria-label="customized table" size="small" id="kapitalundfinanzbedarf-table">
                      <TableHead>
                        <Row>
                          <CustomCell value={"Kapital- und Finanzbedarf"}/>
                          <CustomCell align="right" value={"Wert"}/>
                        </Row>
                      </TableHead>
                      <TableBody>
                        {Object.keys(kapitalUndFinanzierungsrows).map(function (
                          row,
                          index
                        ) {
                          return (
                            <Row
                              key={row}
                              className={
                                kapitalUndFinanzierungsrows[row].class +
                                " step-" +
                                kapitalUndFinanzierungsrows[row].step
                              }
                            >
                              {kapitalUndFinanzierungsrows[row].class === "summe"
                                ? <Fragment>
                                  <CustomDarkCell
                                    value={erklaerungstexte[row]["kurzName"]}
                                    className={kapitalUndFinanzierungsrows[row].class}
                                  />
                                  {isLoaded ?
                                    <CustomDarkCell
                                      align="right"
                                      isCurrency={true}
                                      showCurrency={false}
                                      unit={"€"}
                                      value={kapitalUndFinanzierungsdaten[row]}
                                      className={kapitalUndFinanzierungsrows[row].class}
                                    />
                                    : <CustomDarkCell/>
                                  }
                                </Fragment>
                                : <Fragment>
                                  <CustomCell
                                    value={erklaerungstexte[row]["kurzName"]}
                                    className={kapitalUndFinanzierungsrows[row].class}
                                  />
                                  {isLoaded ?
                                    <CustomCell
                                      align="right"
                                      isCurrency={true}
                                      showCurrency={false}
                                      unit={"€"}
                                      value={kapitalUndFinanzierungsdaten[row]}
                                      className={kapitalUndFinanzierungsrows[row].class}
                                    />
                                    : <CustomCell/>
                                  }
                                </Fragment>
                              }
                            </Row>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {renderItems}
                </div>
              ) : <Typography>
                Die Finanzdaten sind nicht öffentlich sichtbar...
              </Typography>}
            </Grid>
          </Grid>
        </main>
        <AppBar position="fixed" color="transparent" className={classes.progressBar}>
          <Paper variant={'elevation'} square>
            <Toolbar /*className={classes.toolbar}*/
              className={classes.progressBarStepper}
            >
              <Button variant="contained" size="small" color="secondary" onClick={this.back}
                      disabled={false}>
                <KeyboardArrowLeft/> Zurück zum Business Board
              </Button>
              <Button variant="contained" size="small" color="secondary" onClick={this.continue}
                      disabled={false}>
                <KeyboardArrowRight/> Weiter zur Homepage des Unternehmens
              </Button>
            </Toolbar>
          </Paper>
        </AppBar>
      </Fragment>
    );
  }
}

export default withStyles(styles)(SteckbriefShowPublic);
