import Typography from "@material-ui/core/Typography/Typography";
import React, {Fragment} from "react";
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, withStyles} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import styles from "./styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CustomTextField from "./CustomTags/CustomTextField";

import erklaerungsUeberschriften from "./LogicData/erklaerungsUeberschriften"
import CheckIcon from '@mui/icons-material/Check';

import Moment from 'react-moment';
import 'moment-timezone';
import Button from "@material-ui/core/Button";
import {AppContext} from "./AppContext";

class MainArea extends React.Component {
  static contextType = AppContext;

  state = {
    isLoaded: false,
    items: {
      id: "",
      Vorname: "",
      Nachname: "",
      Unternehmen: {
        unternehmenName: "",
        "_id": ""
      },
    },
    newsEntries: [{0: {title: "Lade Titel...", article: "Lade Beitrag..."}}],
    currentNewsEntry: 0,
    chatEntries: [{message: "Lade Titel...", target: {function: "Stundensatz", area: "Geschaeftsdaten"}}],
  };

  getDataFromCockpitApi = () => {
    fetch(`${process.env.REACT_APP_NEWSAPI_URL}/collections/get/News?filter[published]=true&sort[_modified]=-1`, {headers: {'Cockpit-Token': '809f0ae6d0a36d5b231ac68b4e7a5b'}})
      .then((res) => res.json())
      // .then((res) => console.log(res))
      .then(
        (result) => {
          // console.log(result.entries)
          // var unternehmen = result.unternehmen.filter((value) => {
          //   return value._id == this.props.unternehmenId;
          // });
          this.setState({
            isLoaded: true,
            newsEntries: result.entries,
            //   items: {
            //     id: result._id,
            //     Vorname: result.Vorname,
            //     Nachname: result.Nachname,
            //     Unternehmen: unternehmen[0],
            //   },
          });
        },
        (error) => {
          console.log(error)
          this.setState({
            isLoaded: false,
            error: error,
          });
        }
      );
  };

  // componentDidUpdate() {
  //   this.getDataFromApi();
  // }

  async componentDidMount() {
    this.context.setFunktion({
      key: 0,
      name: "Cockpit",
      erklaerungHeader: "Cockpit",
      erklaerungText: "Cockpit",
      continuePossible: false,
      backPossible: false,
      totalSteps: 0,
      handleSubmit: () => this.handleSubmit(),
    });
    this.context.clearMessages();
    // this.getDataFromApi();
    this.getDataFromCockpitApi();
    const readChatEntries = await this.context.getChatDataFromCockpitApi();
    // console.log("readChatEntries:");
    // console.log(readChatEntries);
    this.setState({
      chatEntries: readChatEntries
    });
    // this.context.clearMessages();
  }

  //
  // shouldComponentUpdate(nextProps, nextState) {
  //   return (this.state.items.Unternehmen._id !== nextProps.unternehmenId);
  // }

  // getDataFromApi = () => {
  //   fetch(`${process.env.REACT_APP_API_URL}/v1/userdata/${this.props.userId}`)
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         var unternehmen = result.unternehmen.filter((value) => {
  //           return value._id == this.props.unternehmenId;
  //         });
  //         this.setState({
  //           isLoaded: true,
  //           items: {
  //             id: result._id,
  //             Vorname: result.Vorname,
  //             Nachname: result.Nachname,
  //             Unternehmen: unternehmen[0],
  //           },
  //         });
  //       },
  //       (error) => {
  //         this.setState({
  //           isLoaded: false,
  //           error: error,
  //         });
  //       }
  //     );
  // };

  handleChange = (name) => ({target: {value}}) => {
    this.setState({
      items: {
        ...this.state.items,
        [name]: value,
      },
    });
  };

  handleSubmit = () => {
    // TODO: Validate

    const {items} = this.state;

    fetch(`${process.env.REACT_APP_API_URL}/v1/userdata/${this.props.userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: items["id"],
        Vorname: items["Vorname"],
        Nachname: items["Nachname"],
        TelefonNummer: "Hello",
        EmailAdresse: items["EmailAdresse"],
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error: error,
          });
        }
      );
    console.log(this.state);
  };

  openExternalLink = (url) => {
    window.open(url, '_blank');
  }

  createNewsModuleCard = (module) => {
    const {classes} = this.props;
    const fields = {title: "Titel", article: "Text", link: "Link"}
    const entry = this.state.newsEntries[this.state.currentNewsEntry];
    return (
      <Card className={classes.card}>
        <CardHeader
          title={module}
        />
        <CardContent>
          {/*{Object.keys(fields).map((feld) => {*/}
          {/*  console.log("Verabeite Feld: " + feld);*/}
          {/*  // return this.createField(feld, fields[feld], {typ: "showText"});*/}
          {/*})}*/}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {this.createNewsList()}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" paragraph component="h3">
                {entry.title}
              </Typography>
              <Typography variant={"body1"} className={classes.article}>{entry.article}</Typography>
              {(entry.link) &&
              <Button
                disabled={!(entry.link)}
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => this.openExternalLink(entry.link)}
              >
                {entry.linktext}
              </Button>
              }
              {(entry.link2) &&
              <Button
                disabled={!(entry.link2)}
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => this.openExternalLink(entry.link2)}
              >
                {entry.linktext2}
              </Button>
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  createDoneModuleCard = (module) => {
    const {classes} = this.props;
    const fields = {title: "Titel", article: "Text", link: "Link"}
    return (
      <Card className={classes.card}>
        <CardHeader
          title={module}
        />
        <CardContent>
          {this.createDoneList()}

          {/*<Button*/}
          {/*  disabled={true}*/}
          {/*  variant="contained"*/}
          {/*  color="primary"*/}
          {/*  className={classes.button}*/}
          {/*  onClick={() => this.openExternalLink("https://www.test.de")}*/}
          {/*>*/}
          {/*  Erneut bearbeiten*/}
          {/*</Button>*/}

        </CardContent>
      </Card>
    );
  };

  handleClick = (key) => {
    console.log("secondList clicked for key: " + key)
    this.setState({
      currentNewsEntry: key
    })
  }

  handleDoneClick = (key) => {
    console.log("DoneList clicked for key: " + key)
    // this.setState({
    //   currentNewsEntry: key
    // })
  }

  createNewsList = () => {
    return (
      <List>
        {this.state.newsEntries.map((entry, key) => {
          var t = new Date(entry._modified);
          return (
            <ListItem button onClick={() => this.handleClick(key)} key={key}>
              <ListItemText
                primary={entry.title}
                secondary={<Moment unix format={"lll"} tz="Europe/Berlin" locale={"de"}>{entry._modified}</Moment>}
              />
            </ListItem>
          );
        })
        }
      </List>)
  }

  createDoneList = () => {
    const {classes} = this.props;
    return (
      <List>
        {this.state.chatEntries.map((entry, key) => {
          // let message = entry.target.function + " im Bereich " + entry.target.area || "";
          let message = "";
          if (!(entry.target.area in erklaerungsUeberschriften) || !(entry.target.function in erklaerungsUeberschriften[entry.target.area])) {
            console.log("Chat-Location (" + entry.target.area + "-" + entry.target.function + ") nicht in Erklärungsüberschriften!");
            return
          }
          if (entry.target.month) {
            message = erklaerungsUeberschriften[entry.target.area][entry.target.function].erklaerungHeader + " " + entry.target.month + "/" + entry.target.year + " im Bereich " + entry.target.area || "";
          } else {
            message = erklaerungsUeberschriften[entry.target.area][entry.target.function].erklaerungHeader + " im Bereich " + entry.target.area || "";
          }
          // let message = Object.entries(erklaerungsUeberschriften)[entry.target.area][entry.target.function] || "";
          return (
            <ListItem button onClick={() => this.handleDoneClick(key)} key={key}>
              <ListItemAvatar>
                <Avatar className={classes.doneListItemAvatar}>
                  <CheckIcon/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={message
                  // .replace('Alles klar, deine Eingaben für <em>','')
                  // .replace('</em> habe ich gespeichert! Lass uns mit vielleicht mit einem anderen Zeitraum weitermachen...', '')
                }
                secondary={<Moment unix format={"lll"} tz="Europe/Berlin" locale={"de"}>{entry._modified}</Moment>}
              />
            </ListItem>
          );
        })
        }
      </List>)
  }

  createField = (name, label, properties) => {
    const {classes} = this.props;
    const {items} = this.state;
    if (properties.typ === "readOnly") {
      console.log("generating readOnly Field " + name);
      return (
        <CustomTextField
          id={"edit-liquiditaet-" + name}
          label={label}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name)}
          margin="normal"
          isCurrency={true}
          isReadOnly={true}
          unit={"€"}
          //onFocus={() => this.handleFocus(name)}
        />
      );
    } else if (properties.typ === "input") {
      //console.log("generating input Field " + name);
      return (
        <CustomTextField
          id={"edit-liquiditaet-" + name}
          label={this.erklaerungen[name]["kurzName"]}
          className={classes.textField}
          value={items[name]}
          onChange={this.handleChange(name)}
          margin="normal"
          variant="outlined"
          isCurrency={true}
          isReadOnly={false}
          unit={"€"}
          onFocus={(event) => this.handleFocusEvent(name, event)}
        />
      );
    } else if (properties.typ === "showText") {
      console.log("generating showText Field: " + name);
      return (
        <Typography variant="h5" paragraph component="h3">
          {label}
        </Typography>
        // <CustomTextField
        //   id={"edit-liquiditaet-" + name}
        //   label={label}
        //   className={classes.textField}
        //   value={items[name]}
        //   onChange={this.handleChange(name)}
        //   margin="normal"
        //   isCurrency={true}
        //   isReadOnly={true}
        //   unit={"€"}
        //   //onFocus={() => this.handleFocus(name)}
        // />
      );
    }
  };

  render() {
    const {classes} = this.props;
    const {items} = this.state;

    return (
      <Fragment>
        <div className={classes.appBarSpacer}/>
        <Typography variant="h4" paragraph component="h2">
          Cockpit für {this.context.unternehmenName}
        </Typography>
        <Card className={classes.card}>
          <CardHeader
            title={"Um dein Unternehmen oder deine Idee erfolgreich voranzubringen, empfiehlt dir der Beratungsheld folgende Schritte:"}
          />
          <CardContent>
            <Typography component="ol" gutterBottom>
              <li><Box component="span" sx={{ fontWeight: 'bold' }}>Erstelle zuerst einen knackigen Steckbrief</Box>, um dein Unternehmen oder deine Idee im Beratungsheld Business Forum vorzustellen. Dadurch förderst du dein Netzwerk aus Geschäftspartnern, Investoren und Lieferanten, gewinnst neue Kunden und kannst bei Bedarf auch Unternehmensnachfolger suchen. Hierbei hast du auch die Möglichkeit, auf deine Webseite zu verlinken.</li>
              <li><Box component="span" sx={{ fontWeight: 'bold' }}>Als Gründer oder bei geplanten größeren Investitionen erstelle einen Businessplan.</Box> Dieser besteht aus zwei Teilen: Im Textteil stellst du dein Unternehmen vor, während der Zahlenabschnitt zur Demonstration der Wirtschaftlichkeit dient.</li>
              <li><Box component="span" sx={{ fontWeight: 'bold' }}>Gib monatlich die Zahlen aus deiner Betriebswirtschaftlichen Auswertung (BWA) ein.</Box> Das hilft dir, die finanzielle Performance besser zu verstehen und Trends im Laufe der Zeit zu erkennen.</li>
              <li><Box component="span" sx={{ fontWeight: 'bold' }}>Überprüfe regelmäßig deinen Stundenverrechnungssatz</Box>, um die Auswirkungen von Preissteigerungen in deine Kalkulation einzubeziehen und die Wettbewerbsfähigkeit sicherzustellen.</li>
            </Typography>
          </CardContent>
        </Card>
        {this.createDoneModuleCard("Das hast du zuletzt erledigt:")}
        {this.createNewsModuleCard("Neuigkeiten für Gründer und Unternehmer")}
      </Fragment>
    );
  }
}

export default withStyles(styles)(MainArea);
