const ErsteSchritteSchritte = {
  1: {
    unternehmenName: {
      typ: "singleline",
      datenQuelle: "root",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  2: {
    image: {
      typ: "image",
      datenQuelle: "root",
      datenTyp: "url",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  3: {
    branche: {
      typ: "select",
      datenQuelle: "unternehmenData",
      datenTyp: "select",
      selection: [
        "Baugewerbe",
        "Bergbau und Gewinnung von Steinen und Erden",
        "Dienstleistung",
        "Gastgewerbe",
        "Gesundheits- und Sozialwesen",
        "Grundstücks- und Wohnungswesen",
        "Handel",
        "Handwerk",
        "Information und Kommunikation",
        "Infrastruktur",
        "Kunst, Unterhaltung und Erholung",
        "Land- und Forstwirtschaft, Fischerei",
        "Verarbeitendes Gewerbe",
      ],
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  4: {
    unternehmenBereitsGegruendet: {
      // typ: "toggle",
      typ: "select",
      datenQuelle: "unternehmenData",
      datenTyp: "boolean",
      selection: [
        "noch nicht gegründet",
        "bestehendes Unternehmen",
      ],
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
    unternehmenGruendungsdatum: {
      typ: "date",
      datenQuelle: "unternehmenData",
      datenTyp: "date",
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  },
  5: {
    adresse: {
      typ: "singleline",
      datenQuelle: "unternehmenData",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      // inEingabe: "conditional",
      funktionen: [],
    },
    plz: {
      typ: "singleline",
      datenQuelle: "unternehmenData",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      // inEingabe: "conditional",
      funktionen: [],
    },
    ort: {
      typ: "singleline",
      datenQuelle: "unternehmenData",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      // inEingabe: "conditional",
      funktionen: [],
    },
    bundesland: {
      typ: "select",
      datenQuelle: "unternehmenData",
      datenTyp: "select",
      selection: [
        "Baden-Württemberg",
        "Bayern",
        "Berlin",
        "Brandenburg",
        "Bremen",
        "Hamburg",
        "Hessen",
        "Mecklenburg-Vorpommern",
        "Niedersachsen",
        "Nordrhein-Westfalen",
        "Rheinland-Pfalz",
        "Saarland",
        "Sachsen-Anhalt",
        "Sachsen",
        "Schleswig-Holstein",
        "Thüringen",
      ],
      inAusgabe: true,
      inEingabe: true,
      // inEingabe: "conditional",
      funktionen: [],
    },
    rechtsform: {
      typ: "select",
      datenQuelle: "unternehmenData",
      datenTyp: "select",
      selection: [
        "Einzelunternehmen",
        "Gesellschaft bürgerlichen Rechts (GbR)",
        "Eingetragener Kaufmann (e.K.)",
        "Offene Handelsgesellschaft (OHG)",
        "Kommanditgesellschaft (KG)",
        "Gesellschaft mit beschränkter Haftung (GmbH)",
        "Unternehmergesellschaft (UG)",
        "Aktiengesellschaft (AG)",
        "GmbH & Co. KG",
        "eingetragener Verein (e.V.)",
      ],
      inAusgabe: true,
      inEingabe: true,
      // inEingabe: "conditional",
      funktionen: [],
    },
    url: {
      typ: "singleline",
      datenQuelle: "unternehmenData",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      // inEingabe: "conditional",
      funktionen: [],
    },
    email: {
      typ: "singleline",
      datenQuelle: "unternehmenData",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      // inEingabe: "conditional",
      funktionen: [],
    },
    telefon: {
      typ: "singleline",
      datenQuelle: "unternehmenData",
      datenTyp: "text",
      inAusgabe: true,
      inEingabe: true,
      // inEingabe: "conditional",
      funktionen: [],
    },
  },
  6: {
    gesuchtWird: {
      typ: "multiselect",
      // typ: "select",
      datenQuelle: "unternehmenData",
      datenTyp: "select",
      selection: [
        "Kapital",
        "Neue Kunden",
        "Neue Lieferanten",
        "Erfahrung",
        "Unternehmensnachfolge",
        "Beratung",
      ],
      inAusgabe: true,
      inEingabe: true,
      funktionen: [],
    },
  }
};

export default ErsteSchritteSchritte;
