const topBarHeight = 64;
const drawerWidth = `calc(33%)`;
const contentWidth = `calc(67%)`;
// const contentWidth = `calc(100%)`;
// const drawerWidth = 500;

const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: topBarHeight,
  },
  mobilWarningBanner: {
    position: "absolute",
    top: "0px",
    left: "0px",
    // margin: "30 auto",
    // marginBottom: "30px",
    textAlign: "center",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    zIndex: 100000,
    width: "100%",
  },
  mobilWarningBannerContent: {},
  menuPaper: {
    backgroundColor: theme.palette.secondary.light,
  },
  menuIcon: {
    minWidth: '34px',
  },
  menuItemActive: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 30,
  },
  progressBar: {
    top: 'auto',
    bottom: 0,
  },
  progressBarStepper: {
    justifyContent: 'space-evenly !important',
    "-webkit-justify-content": 'space-evenly !important',
  },
  progressButton: {
    color: 'black',
  },
  progressBarDots: {
    // backgroundColor: theme.palette.secondary.main,
  },
  progressBarDot: {
    width: '32px !important',
    height: '32px !important',
    marginLeft: 10,
    marginRight: 10,
  },
  progressBarDotActive: {
    backgroundColor: theme.palette.secondary.main + ' !important',
  },
  chatCardAssistent: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    margin: theme.spacing(2),
    marginRight: theme.spacing(6),
    overflow: 'visible',
  },
  chatCardUser: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    margin: theme.spacing(2),
    marginLeft: theme.spacing(6),
    overflow: 'visible',
  },
  chatCardSend: {
    width: '100%',
    backgroundColor: theme.palette.secondary.light,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  chatCardMenu: {
    backgroundColor: theme.palette.secondary.main,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  chatCardSendNavigation: {
    backgroundColor: theme.palette.secondary.dark,
    color: "white !important",
  },
  chatIcon: {
    color: "white !important",
  },
  fieldButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.main + " !important",
    // color: theme.palette.secondary.light + " !important",
    color: "white !important",
    textTransform: "initial",
  },
  fieldIcon: {
    //color: theme.palette.primary.main
  },
  fieldActiveIcon: {
    color: theme.palette.primary.main
  },
  fieldIconSpan: {
    whiteSpace: "nowrap",
    // marginBottom: theme.spacing(3),
  },
  chatButtonTypography: {
    textAlign: "center",
    color: theme.palette.secondary.dark,
  },
  toolbar: {
    // paddingRight: 24, // keep right padding when drawer closed
    // backgroundColor: "#ffffff",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  doneListItemAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  mainPagination: {
    //backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
    // zIndex: 1250,
  },
  // appBarShift: {
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   transition: theme.transitions.create(['margin', 'width'], {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  //   marginRight: drawerWidth,
  // },
  hide: {
    display: 'none',
  },
  drawer: {
    width: 0,
    // width: drawerWidth,
    flexShrink: 0,
  },
  drawerOuterPaper: {
    marginTop: topBarHeight,
    width: drawerWidth,
    overflowY: 'hidden',
    background: 'none',
    border: 'none',

  },
  drawerInnerPaper: {
    // overflowY: 'scroll',
    overflowY: 'hidden',
    borderBottomLeftRadius: 0,
    borderBottomRightRdius: 0,
    background: 'none',
    marginTop: 'auto',
    marginBottom: 200,
    //backgroundColor: '#fafafa',
  },
  drawerFooter: {
    zIndex: theme.zIndex.drawer + 1,
    // display: 'flex',
    // alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    position: 'fixed',
    bottom: 0,
    right: 10,
    width: drawerWidth.slice(0, -1) + " - 3px)",
    // width: '100%',
    // maxWidth: '100%',
  },
  fullheightGrid: {
    height: "100%",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: drawerWidth,
    height: `calc(100vh - ${topBarHeight}px - 48px)`,
    width: contentWidth,
    // overflowX: 'scroll',
  },
  // content: {
  //   flexGrow: 1,
  //   padding: theme.spacing(3),
  //   height: "100vh",
  //   overflow: "auto",
  // },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  contentItem: {
    overflowX: 'initial'
  },
  menuButton: {
    // color: "#ffffff",
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  topMenu: {
    flexGrow: 2,
  },
  title: {
    flexGrow: 1,
    fontWeight: "900",
    letterSpacing: "4px",
  },
  logo: {
    flexGrow: 1,
    // fontWeight: "900",
    // letterSpacing: "4px",
  },
  navListItemActive: {
    backgroundColor: theme.palette.secondary.light,
  },
  leftMenu: {
    backgroundColor: "#1976d2",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    minWidth: drawerWidth,
  },
  leftMenuList: {
    color: "#ffffff",
  },
  appBarSpacer:
  // theme.mixins.toolbar,
    {
      minHeight: 20
    },
  appBarBottomSpacer:
  // theme.mixins.toolbar,
    {
      minHeight: 60
    },
  card: {
    maxWidth: 1312,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  steckbriefButton: {
    width: "40%",
  },
  steckbriefFrontCard: {
    // backgroundImage: 'url("https://cockpit.beratungsheld.de/gomwell_wolke_b.png")',
    // backgroundImage: 'url("https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Solid_red.svg/2048px-Solid_red.svg.png")',
    display: 'flex',
    flexDirection: 'column',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    maxWidth: 1312,
    height: 400,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  steckbriefFrontCardContent: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  steckbriefBackCard: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.main,
    maxWidth: 1312,
    height: 400,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: "white !important",
  },
  steckbriefBackCardContent: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullWidthCard: {
//    maxWidth: 1312,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  floatingButtons: {
    backgroundColor: theme.palette.secondary.main + ' !important',
    color: theme.palette.background.default + ' !important',
  },
  bottomRightMessageWrapper: {
    bottom: 68,
    position: "fixed",
    width: 300,
    justifyContent: "flex-end",
    display: "flex",
    right: 20,
    flexDirection: "column",
    alignItems: "flex-end",
    gap: 12,
  },
  bottomRightMessage: {
    position: "initial"
  },
  bottomRightMessageAbove: {
    // color: theme.palette.primary.main + '!important',
    bottom: 368,
    maxWidth: 300,
    position: "fixed",
  },
  bottomRightMessageContent: {
    backgroundColor: theme.palette.primary.main + '!important',
  },
  progressCard: {
    maxWidth: 1312,
    zIndex: theme.zIndex.drawer + 1,
    // display: 'flex',
    // alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: contentWidth.slice(0, -1) + " - 3px)",
    //width: '100%',
  },
  moduleCard: {},
  moduleCardHeader: {
    backgroundColor: theme.palette.background.default,
  },
  moduleCardHeaderAction: {
    flex: "1 1 auto",
    marginRight: 8,
  },
  moduleCardHeaderTitle: {
    fontSize: "1rem",
  },
  moduleTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  sizeUp: {
    // fontSize: "1.5rem",
  },
  moduleFieldButton: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main + " !important",
    // color: theme.palette.secondary.light + " !important",
    color: "white !important",
    textTransform: "initial",
    // fontSize: "1.40625rem",
  },
  rightcard: {
    // maxWidth: 1312,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  bottomcard: {
    // maxWidth: 1312,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  userData: {
    // marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    margin: theme.spacing(2),
  },
  article: {
    hyphens: 'auto !important',
    whiteSpace: 'pre-line',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  wideTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 650,
  },
  multilineTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  formControl: {
    marginTop: theme.spacing(2) + "px !important",
    marginLeft: theme.spacing(1) + "px !important",
    marginRight: theme.spacing(1) + "px !important",
    width: 300,
  },
  formControlWide: {
    marginTop: theme.spacing(2) + "px !important",
    marginLeft: theme.spacing(0) + "px !important",
    marginRight: theme.spacing(2) + "px !important",
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  chartCard: {
    maxWidth: 1312,
    marginTop: 0,
    // marginBottom: theme.spacing(5),
    paddingTop: 0,
    paddingBottom: "0px !important",
  },
  chartCardHeader: {
    // paddingTop: 0,
    paddingBottom: 0
  },
  chartCardContent: {
    paddingTop: 0,
    paddingBottom: 0
  },
  chart: {
    height: "100%",
    width: "100%",
    // minHeight: "250px",
  },
  modal: {
    top: "20%",
    left: "30%",
    transform: "translate(-20%, -20%)",
    position: "absolute",
    width: "60%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  summe: {
    paddingBottom: "80px",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  feldValidierung: {
    color: "red",
  },
  unternehmenClose: {
    marginLeft: "10px",
  },
  calculateButton: {
    marginBottom: "20px",
  },

  switchMonatJahr: {
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    label: {
      color: 'blue'
    },
  },
  businessplanContent: {
    display: "none",
  },
  calendar: {
    margin: "2em auto",
  },
});

export default styles;
